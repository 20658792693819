<template>
  <div>
    <span v-if="selectedVehicle" class="page-title"
      >{{ $t("Vehicle")
      }}{{ this.$store.getters.selectedVehicle.licensePlateNumber }}</span
    >
    <span v-else class="page-title">{{ $t("NewVehicle") }}</span>
    <router-link v-bind:to="{ name: 'MyVehicles' }">
      <b-button size="lg" class="float-right m-2 mr-5 action-btn center-it">
        <span>{{ $t("BackText") }}</span>
      </b-button>
    </router-link>
    <div class="page-separator"></div>
    <b-overlay :show="processing">
      <b-card>
        <b-form @submit="onSave" class="mt-4">
          <label for="feedback-licensePlate">{{
            $t("LicensePlateEdit")
          }}</label>
          <b-form-input
            v-model="vehicle.licensePlateNumber"
            :state="isLicensePlateValid"
            id="feedback-licensePlate"
            class="form-edit"
            :placeholder="$t('LicensePlatePlaceholderText')"
            maxlength="20"
            :disabled="selectedVehicle != null"
          >
          </b-form-input>
          <b-form-invalid-feedback
            :state="isLicensePlateValid"
            id="feedback-licensePlate"
          >
            {{ $t("LicensePlateValidationText") }}
          </b-form-invalid-feedback>

          <br />

          <label for="feedback-alias">{{ $t("AliasEdit") }}</label>
          <b-form-input
            v-model="vehicle.alias"
            :state="isAliasValid"
            id="feedback-alias"
            class="form-edit"
            :placeholder="$t('AliasPlaceholderText')"
            maxlength="20"
          >
          </b-form-input>
          <b-form-invalid-feedback :state="isAliasValid" id="feedback-alias">
            {{ $t("AliasValidationText") }}
          </b-form-invalid-feedback>

          <br />

          <div class="row">
            <div class="col-2">
              <BIconClockFill class="h1 ml-2" />
            </div>
            <div class="col-9">
              <div class="custom-control custom-switch custom-switch-xl">
                <input
                  type="checkbox"
                  v-model="vehicle.timeBounded"
                  class="custom-control-input"
                  id="timeCheckbox"
                />
                <label class="custom-control-label" for="timeCheckbox">{{
                  $t("TimeScopedEdit")
                }}</label>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-2">
              <IconParking class="ml-2" />
            </div>
            <div class="col-10 mt-1">
              {{ $t("ParkingAgreementsEdit") }}
            </div>
          </div>

          <div
            class="row"
            :class="vehicle.parkingAgreementId === null ? 'active' : ''"
          >
            <div class="custom-control custom-radio custom-radio-xl">
              <input
                type="radio"
                class="custom-control-input"
                name="parkingAgreementsRadios"
                id="none"
                :value="null"
                v-model="vehicle.parkingAgreementId"
                autocomplete="off"
              />
              <label class="custom-control-label" for="none">
                {{ $t("NoneParkingAgreement") }}
              </label>
            </div>
          </div>

          <div
            class="row"
            :class="vehicle.parkingAgreementId === agreement.id ? 'active' : ''"
            v-for="agreement in agreements"
            :key="agreement.id"
          >
            <div class="custom-control custom-radio custom-radio-xl">
              <input
                type="radio"
                class="custom-control-input"
                name="parkingAgreementsRadios"
                :id="agreement.id"
                :value="agreement.id"
                v-model="vehicle.parkingAgreementId"
                autocomplete="off"
              />
              <label class="custom-control-label" :for="agreement.id">
                {{ $t("DealText") }}{{ agreement.title }}
              </label>
            </div>
          </div>

          <b-button
            @click="onSave"
            size="lg"
            class="action-btn float-right mt-5"
          >
            <span>{{ $t("SaveText") }}</span>
          </b-button>
          <b-button
            @click="onRemove"
            size="lg"
            class="action-btn-danger float-left mt-5"
          >
            <BIconTrash font-scale="1.6" />
          </b-button>
        </b-form>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { routes } from "@/router";
import { actions } from "@/store";
import Vehicle from "@/store/Vehicle";
import IconParking from "@/components/IconParking";

export default {
  name: "VehicleDetails",
  components: { IconParking },
  data() {
    return {
      vehicle: Vehicle.EmptyInstance(),
      isLicensePlateValid: null,
      isAliasValid: null,
      processing: false,
    };
  },
  computed: {
    agreements() {
      return this.$store.getters.agreements;
    },
    selectedVehicle() {
      return this.$store.getters.selectedVehicle;
    },
  },
  methods: {
    validate() {
      this.isLicensePlateValid = !!this.vehicle.licensePlateNumber;
      this.isAliasValid = !!this.vehicle.alias;

      if (this.isLicensePlateValid) {
        const pattern = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;
        const regex = new RegExp(pattern);
        this.isLicensePlateValid = !regex.test(this.vehicle.licensePlateNumber);
      }

      return this.isLicensePlateValid && this.isAliasValid;
    },
    onSave(event) {
      if (!this.validate()) {
        event.preventDefault();
        return;
      }

      if (this.processing === true) {
        return;
      }
      this.processing = true;

      const action =
        this.vehicle.id === "" ? actions.CREATE_VEHICLE : actions.SAVE_VEHICLE;
      this.$store
        .dispatch(action, this.vehicle)
        .then(() => {
          this.delay(500).then(() => {
            console.log("saved");
            this.processing = false;
            this.$router.push(routes.MY_VEHICLES.path).catch(() => {});
          });
        })
        .catch((_) => {
          this.processing = false;
        });
    },
    onRemove(event) {
      if (this.processing === true) {
        return;
      }
      this.processing = true;

      if (this.vehicle.id) {
        this.$store
          .dispatch(actions.REMOVE_VEHICLE, this.vehicle)
          .then(() => {
            this.delay(500).then(() => {
              console.log("removed");
              this.processing = false;
              this.$router.push(routes.MY_VEHICLES.path).catch(() => {});
            });
          })
          .catch((_) => {
            this.processing = false;
          });
      }
    },
  },
  mounted() {
    if (this.$store.getters.selectedVehicle) {
      this.vehicle = this.$store.getters.selectedVehicle;
    } else {
      this.vehicle = Vehicle.EmptyInstance();

      const freeAgreementId = this.$store.getters.freeAgreementId;
      if (freeAgreementId) {
        this.vehicle.parkingAgreementId = freeAgreementId;
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "src/style/style";

.form-edit {
  height: 40px;
  font-size: 1.8rem;
}

// Bigger radio buttons

.custom-radio-xl {
  margin-left: 7.5rem;
}

.custom-radio.custom-radio-xl .custom-control-label {
  padding-left: 2rem;
  padding-bottom: 1.5rem;
  padding-top: 3px;
}

.custom-radio.custom-radio-xl .custom-control-label::before {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 5rem;
}

.custom-radio.custom-radio-xl .custom-control-label::after {
  width: 2.48rem;
  height: 2.48rem;
  border-radius: 4rem;
}

.custom-radio.custom-radio-xl
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: $primary;
  border-color: $primary;
}

// Bigger switch control

.custom-switch.custom-switch-xl .custom-control-label {
  padding-left: 4rem;
  padding-bottom: 2.5rem;
  padding-top: 3px;
}

.custom-switch.custom-switch-xl .custom-control-label::before {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::after {
  width: calc(2.5rem - 4px);
  height: calc(2.5rem - 4px);
  border-radius: calc(4rem - (2.5rem / 2));
}

.custom-switch.custom-switch-xl
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(2.5rem - 0.25rem));
}

.custom-switch.custom-switch-xl
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: $primary;
  border-color: $primary;
}
</style>
