<template>
  <div>
    <span class="page-title">{{ $t("MyVehiclesPageTitle") }}</span>
    <h1 v-if="readonly" class="text-information mx-5">
      {{ $t("ReadonlyText") }}
    </h1>
    <router-link
      :disabled="isVehiclesLimitReached || readonly"
      v-bind:to="{ name: 'NewVehicle' }"
    >
      <b-button
        :disabled="isVehiclesLimitReached || readonly"
        size="lg"
        class="float-right m-2 mr-5 action-btn center-it"
      >
        <span>{{ $t("AddNewBtn") }}</span
        ><BIconPlus font-scale="2" />
      </b-button>
    </router-link>
    <div class="page-separator"></div>

    <ParkingAgreementsList />

    <br />

    <VehiclesList />

    <b-alert v-model="showErrorAlert" variant="danger" dismissible>
      {{ $t("ErrorMessage") }}
    </b-alert>
  </div>
</template>

<script>
import VehiclesList from "@/components/VehiclesList.vue";
import ParkingAgreementsList from "@/components/ParkingAgreementsList";

export default {
  name: "MyVehicles",
  components: {
    ParkingAgreementsList,
    VehiclesList,
  },
  computed: {
    isVehiclesLimitReached() {
      return this.$store.getters.isVehiclesLimitReached;
    },
    showErrorAlert() {
      return this.$store.getters.showError;
    },
    readonly() {
      return this.$store.getters.readonlyStatus;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/style/style";

.parking-agreement {
  position: absolute;
  bottom: 10px;
  width: 95%;
  color: $text;
  padding: 10px;
}

.parking-agreement-title {
  font-size: 1.7rem;
  margin: 10px;
}

.parking-agreement-action {
  color: $primary;
}
</style>
