<template>
  <div id="parking-agreements-list">
    <b-list-group>
      <b-list-group-item
        v-for="agreement in agreements"
        :key="agreement.id"
        class="parking-agreements-list-item"
      >
        <router-link
          v-bind:to="{
            name: 'ParkingAgreementsDetails',
            params: { id: agreement.id },
          }"
        >
          <b-card>
            <a class="h2 float-left m-3 mt-4 mr-4">
              <BIconFileEarmarkText id="icon" font-scale="1.6" />
            </a>
            <span class="align-middle">
              <div class="row">
                <div class="agreement mt-3 mb-1">
                  {{ $t("ParkingAgreementLicensePlate") }}
                  <span class="agreement-title"
                    ><b>{{ agreement.title }}</b></span
                  >
                </div>
              </div>
              <div class="row">
                <div
                  v-if="agreement.linkedLicensePlate"
                  class="linked-licensePlate"
                >
                  <b>
                    {{ $t("LinkedLicensePlate") }}
                    {{ agreement.linkedLicensePlate }}</b
                  >
                </div>
                <div
                  v-if="agreement.type === 'ReservationParking'"
                  class="required-res-info"
                >
                  <b>{{ $t("ResRequiredInfo") }}</b>
                </div>
              </div>
            </span>
          </b-card>
        </router-link>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { actions } from "@/store";

export default {
  name: "ParkingAgreementsList",
  computed: {
    agreements() {
      return this.$store.getters.agreements;
    },
  },
  created() {
    this.$store.dispatch(actions.LOAD_AGREEMENTS);
  },
};
</script>

<style scoped lang="scss">
@import "src/style/variables";

#parking-agreements-list {
  .parking-agreements-list-item {
    color: $text !important;
    width: 100%;
    border: none;
    background: none;
    padding: 0rem 1rem !important;
  }

  .agreement {
    color: $text;
    font-size: 1.5rem;
  }

  .agreement-code {
    color: $text;
    font-size: 1.3rem;
  }

  .agreement-title {
    color: $text;
    font-size: 1.3rem;
    margin-left: 5px;
    text-align: left;
  }

  .required-res-info {
    color: $danger;
    font-size: 1rem;
    margin-left: 12px;
    margin-top: 2px;
  }

  #icon {
    color: $primary;
  }

  .linked-licensePlate {
    color: $text;
  }
}
</style>
