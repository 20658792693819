const actions = {
  CLEAR_VEHICLES: "CLEAR_VEHICLES",
  LOAD_VEHICLES: "LOAD_VEHICLES",
  SAVE_VEHICLE: "SAVE_VEHICLE",
  REMOVE_VEHICLE: "REMOVE_VEHICLE",
  CREATE_VEHICLE: "CREATE_VEHICLE",
  LOAD_AGREEMENTS: "LOAD_AGREEMENTS",
};

export default actions;
