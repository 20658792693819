export default class Vehicle {
  constructor(id, licensePlateNumber, alias, parkingAgreementId, timeBounded) {
    this.id = id;
    this.licensePlateNumber = licensePlateNumber;
    this.alias = alias;
    this.parkingAgreementId = parkingAgreementId;
    this.timeBounded = timeBounded;
  }

  static EmptyInstance() {
    return new Vehicle("", "", "", null, false);
  }
}
