import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/router/routes";
import store from "@/store";
import MyVehicles from "@/views/MyVehicles.vue";
import VehicleDetails from "@/components/VehicleDetails";
import ParkingAgreementDetails from "@/components/ParkingAgreementDetails";

Vue.use(VueRouter);

const pages = [
  {
    path: routes.MY_VEHICLES.path,
    name: routes.MY_VEHICLES.name,
    component: MyVehicles,
  },
  {
    path: routes.NEW_VEHICLE.path,
    name: routes.NEW_VEHICLE.name,
    component: VehicleDetails,
    beforeEnter: (to, from, next) => {
      store.commit("clearErrors");
      if (store.getters.isVehiclesLimitReached) {
        next(false);
      } else {
        store.commit("unselectVehicle");
        next();
      }
    },
  },
  {
    path: routes.VEHICLE_DETAILS.path,
    name: routes.VEHICLE_DETAILS.name,
    component: VehicleDetails,
    beforeEnter: (to, from, next) => {
      store.commit("clearErrors");
      if (to.params.id) {
        store.commit("pickVehicle", to.params.id);
      }
      next();
    },
  },
  {
    path: routes.PARKING_AGREEMENT_DETAILS.path,
    name: routes.PARKING_AGREEMENT_DETAILS.name,
    component: ParkingAgreementDetails,
    beforeEnter: (to, from, next) => {
      store.commit("clearErrors");
      if (to.params.id) {
        store.commit("pickAgreement", to.params.id);
      }
      next();
    },
  },
];

const router = new VueRouter({
  mode: "abstract",
  routes: pages,
});

export default router;
export { default as routes } from "./routes";
