<template>
  <b-overlay :show="isLoading">
    <div id="vehicles-list">
      <b-list-group v-if="!isEmpty">
        <b-list-group-item
          v-for="vehicle in vehicles"
          :key="vehicle.id"
          class="vehicles-list-item"
        >
          <router-link
            v-bind:to="
              readonly
                ? '#'
                : { name: 'VehicleDetails', params: { id: vehicle.id } }
            "
          >
            <b-card v-if="isValid(vehicle)">
              <span class="float-left align-middle">
                <div class="agreement-code m-2">
                  <IconCar class="mr-3" />
                  <b>{{ vehicle.licensePlateNumber }}</b>
                </div>
                <div class="agreement-title">
                  {{ vehicle.alias }}
                </div>
              </span>
              <span
                v-if="
                  agreements.find(
                    (v) => v.linkedLicensePlate == vehicle.licensePlateNumber
                  )
                "
                class="h2 float-right m-3 mt-4"
              >
                <IconParking />
              </span>
            </b-card>
          </router-link>
        </b-list-group-item>
      </b-list-group>
      <span v-if="isEmpty && !isLoading" class="m-1 text-center">
        <h2 class="m-4">{{ $t("EmptyListTitleLine1") }}</h2>
        <h2 class="m-4">{{ $t("EmptyListTitleLine2") }}</h2>
        <h2 class="m-4">{{ $t("EmptyListTitleLine3") }}</h2>
        <h2 class="m-4">{{ $t("EmptyListTitleLine4") }}</h2>
      </span>
    </div>
  </b-overlay>
</template>

<script>
import { actions } from "@/store";
import IconParking from "@/components/IconParking";
import IconCar from "@/components/IconCar";

export default {
  name: "VehiclesList",
  components: { IconParking, IconCar },
  computed: {
    isLoading() {
      return this.$store.getters.loadingStatus;
    },
    isEmpty() {
      return !this.$store.getters.anyVehicles;
    },
    vehicles() {
      return this.$store.getters.registeredVehicles;
    },
    agreements() {
      return this.$store.getters.agreements;
    },
    readonly() {
      return this.$store.getters.readonlyStatus;
    },
  },
  mounted() {
    this.$store
      .dispatch(actions.CLEAR_VEHICLES)
      .then(() => this.$store.dispatch(actions.LOAD_VEHICLES));
  },
  methods: {
    isValid(vehicle) {
      if (vehicle) {
        if (vehicle.id && vehicle.id !== "") {
          return true;
        }
      }

      return false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/style/variables";

#vehicles-list {
  .vehicles-list-item {
    color: $text !important;
    width: 100%;
    border: none;
    background: none;
    padding: 0rem 1rem !important;
  }

  .agreement-code {
    color: $text;
    font-size: 1.8rem;
    margin-right: 12px;
  }

  .agreement-title {
    color: $text;
    margin-left: 6px;
    text-align: left;
  }

  .agreement-code {
    color: $text;
    margin-left: 6px;
    text-align: left;
  }

  .vehicle-edit-btn {
  }

  #edit-icon {
    color: $primary;
  }

  #add-icon {
    color: $primary;
    margin-top: 4px;
  }

  #remove-icon {
    color: $danger;
    margin-top: 12px;
  }
}
</style>
