export const en = {
  MyVehiclesPageTitle: "MY VEHICLES",
  AddNewBtn: "New",
  NewVehicle: "NEW VEHICLE",
  Vehicle: "VEHICLE - ",
  EmptyListTitleLine1: "You can register up to 3 vehicles.",
  EmptyListTitleLine2: "The vehicles can be yours or your guests.",
  EmptyListTitleLine3:
    "If you have a parking agreement, you must choose which vehicle is to be linked to the agreement.",
  EmptyListTitleLine4:
    "Independent on parking, you must register which vehicle (s) you are arriving with to enter the gate.",
  LicensePlateEdit: "License plate",
  LicensePlateValidationText:
    "License plate number cannot be empty and must consist of letters and numbers only.",
  LicensePlatePlaceholderText: "Enter license plate number",
  AliasEdit: "Name of vehicle",
  AliasValidationText: "Alias cannot be empty.",
  AliasPlaceholderText: "Enter some name for vehicle",
  BackText: "Back",
  SaveText: "Save",
  ParkingAgreementsListTitle: "Parking agreement",
  ParkingAgreementsEdit: "Attached to parking agreement:",
  DealText: "Deal ",
  TimeScopedEdit: "Is this a rental car",
  ParkingAgreementLicensePlate: "Parking agreement",
  ParkingAgreementLicensePlateEmpty: "Choose vehicle",
  NoneParkingAgreement: "None",
  ParkingAgreementDescriptionLineLongTerm:
    "Your agreement gives you the right to park for free on Oslofjord's parking areas.",
  ParkingAgreementDescriptionLine1Reservation:
    "Your agreement gives you the right to park for free on Oslofjord's parking areas when you have an active reservation on the suite rental agreement.",
  ParkingAgreementDescriptionLine1Sommer:
    "Your agreement gives you the right to park for free on Oslofjord's parking areas when you have an active reservation on the summer agreement.",
  ParkingAgreementDescriptionLine1Parkering:
    "Your agreement gives you the right to park free of charge in the parking area allocated by Brunstadstiftelsen.",
  ParkingAgreementDescriptionLine2:
    "The agreement is valid for one vehicle at a time.",
  ParkingAgreementDescriptionLine3:
    "You are responsible for ensuring that the correct vehicle is attached to the agreement at all times.",
  RemoveVehicleQuestion: "Are you sure you want to delete the vehicle ?",
  Yes: "Yes",
  No: "No",
  ErrorMessage:
    "At the moment, you cannot add new or change information about the vehicle.",
  ResRequiredInfo: "Requires suite reservation",
  LinkedLicensePlate: "Linked to:",
  ReadonlyText: "Readonly - You can\t make changes",
  AgreementChangedVehicle: "{person} changed the vehicle to {newVehicle}",
  ParkingAgreementHistory: "History",
};

export default en;
