export default class ParkingAgreement {
  LongTermAgreementType = "LongTermParking";
  ReservationAgreementType = "ReservationParking";

  constructor(id, code, title, type) {
    this.id = id;
    this.code = code;
    this.title = title;
    this.type = type;
  }

  static EmptyInstance() {
    return new ParkingAgreement("", "", "", "");
  }
}
