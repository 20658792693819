import Vue from "vue";
import Vuex from "vuex";
import http from "@/http";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    vehicles: [],
    selectedVehicleId: "",
    agreements: [],
    selectedAgreementId: "",
    loading: false,
    readonly: false,
    error: "",
  },
  getters: {
    anyVehicles: (state) => {
      return state.vehicles.length;
    },

    registeredVehicles: (state) => {
      return state.vehicles;
    },

    isVehiclesLimitReached: (state) => {
      return state.vehicles.length === 3;
    },

    selectedVehicle: (state) => {
      if (state.selectedVehicleId === "") {
        return null;
      }

      return state.vehicles.find((v) => v.id === state.selectedVehicleId);
    },

    agreements: (state) => {
      return state.agreements;
    },

    selectedAgreement: (state) => {
      return state.agreements.find((a) => a.id === state.selectedAgreementId);
    },

    licensePlateForAgreement: (state) => {
      const vehicleWithAgreement = state.vehicles.find(
        (v) => v.parkingAgreementId === state.selectedAgreementId
      );
      if (vehicleWithAgreement) {
        return vehicleWithAgreement.licensePlateNumber;
      }

      return null;
    },

    freeAgreementId: (state) => {
      if (state.vehicles.length === 0) {
        const agreement = state.agreements[0];
        if (agreement) {
          return agreement.id;
        }
      }

      return null;
    },

    loadingStatus: (state) => {
      return state.loading;
    },

    readonlyStatus: (state) => state.readonly,

    showError: (state) => {
      return state.error !== "";
    },
  },
  mutations: {
    clearVehicles: (state) => {
      state.vehicles = [];
    },
    setVehicles(state, vehicles) {
      state.vehicles = vehicles;
    },
    pickVehicle(state, vehicleId) {
      state.selectedVehicleId = vehicleId;
    },
    unselectVehicle(state) {
      state.selectedVehicleId = "";
    },
    createVehicle(state, vehicle) {
      const existingVehicle = state.vehicles.find((v) => v.id === vehicle.id);
      if (!existingVehicle) {
        state.vehicles.push(vehicle);
      }
    },
    saveVehicle(state, vehicle) {
      const existingVehicle = state.vehicles.find((v) => v.id === vehicle.id);
      if (existingVehicle) {
        existingVehicle.id = vehicle.id;
        existingVehicle.licensePlateNumber = vehicle.licensePlateNumber;
        existingVehicle.alias = vehicle.alias;
        existingVehicle.parkingAgreementId = vehicle.parkingAgreementId;
        existingVehicle.timeBounded = vehicle.timeBounded;
      }
    },
    removeVehicle(state, vehicle) {
      const existingVehicle = state.vehicles.find((v) => v.id === vehicle.id);
      if (existingVehicle) {
        const indexOfElement = state.vehicles.indexOf(existingVehicle);
        state.vehicles.splice(indexOfElement, 1);
      }
    },
    setAgreements(state, agreements) {
      state.agreements = agreements;
    },
    pickAgreement(state, agreementId) {
      state.selectedAgreementId = agreementId;
    },
    loadingStatus(state, status) {
      state.loading = status;
    },

    setReadonly(state, value) {
      state.readonly = value;
    },

    clearErrors(state) {
      state.error = "";
    },
  },
  actions: {
    CLEAR_VEHICLES(context) {
      context.commit("clearVehicles", true);
    },
    LOAD_VEHICLES(context) {
      context.commit("loadingStatus", true);
      http.requester
        .get("/api/vehicles")
        .then((res) => {
          context.commit("setVehicles", res.data);
        })
        .catch((e) => {
          console.log(e);
        })
        .then((_) => {
          context.commit("loadingStatus", false);
        });
    },
    CREATE_VEHICLE(context, vehicle) {
      http.requester
        .post("/api/vehicles", vehicle)
        .then((res) => {
          context.commit("createVehicle", res.data);
        })
        .catch((e) => {
          context.state.error = e.message;
          console.log(e);
        });
    },
    SAVE_VEHICLE(context, vehicle) {
      http.requester
        .put("/api/vehicles", vehicle)
        .then((res) => {
          context.commit("saveVehicle", res.data);
        })
        .catch((e) => {
          context.state.error = e.message;
          console.log(e);
        });
    },
    REMOVE_VEHICLE(context, vehicle) {
      http.requester
        .delete(`/api/vehicles/${vehicle.id}`)
        .then((res) => {
          context.commit("removeVehicle", vehicle);
        })
        .catch((e) => {
          context.state.error = e.message;
          console.log(e);
        });
    },
    LOAD_AGREEMENTS(context) {
      http.requester
        .get("/api/agreements")
        .then((res) => {
          context.commit("setAgreements", res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
});

export default store;
export { default as actions } from "./actions";
