<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import BootstrapVue, {
  BIconPlus,
  BIconPencilSquare,
  BIconTrash,
  BIconCreditCard,
  BIconFileEarmarkText,
  BIconChevronCompactRight,
  BIconClockFill,
} from "bootstrap-vue";
import http from "@/http";
import store from "@/store";
import router, { routes } from "@/router";
import i18n, { setLang } from "@/localization";

Vue.use(BootstrapVue);
Vue.use(store);

Vue.component("BIconPlus", BIconPlus);
Vue.component("BIconPencilSquare", BIconPencilSquare);
Vue.component("BIconTrash", BIconTrash);
Vue.component("BIconCreditCard", BIconCreditCard);
Vue.component("BIconFileEarmarkText", BIconFileEarmarkText);
Vue.component("BIconChevronCompactRight", BIconChevronCompactRight);
Vue.component("BIconClockFill", BIconClockFill);

Vue.mixin({
  methods: {
    delay(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
  },
});

export default {
  name: "App",
  data() {
    return {
      loaded: false,
    };
  },
  props: {
    apiBasePath: {
      type: String,
      required: true,
    },
    accessToken: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    impersonateEmail: {
      type: String,
      required: false,
    },
    impersonatePersonKey: {
      type: String,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
  },
  i18n,
  router,
  store,
  watch: {
    lang: {
      immediate: true,
      handler(path) {
        setLang(path);
      },
    },
  },
  methods: {
    loadData() {
      if (this.apiBasePath && this.accessToken) {
        this.loaded = true;

        http.setApiPath(this.apiBasePath);
        http.setAccessToken(this.accessToken);

        if (this.impersonatePersonKey) {
          http.setImpersonatePersonKey(this.impersonatePersonKey);
        }

        if (this.impersonateEmail) {
          http.setImpersonatePersonKey(this.impersonateEmail);
        }

        this.$store.commit("clearErrors");
        this.$store.commit("setReadonly", this.readonly);
        router.push(routes.MY_VEHICLES.path).catch(() => {});
      }
    },
  },
  mounted() {
    setLang(this.lang || "en");
    this.loadData();
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
@import "style/variables.scss";
@import "style/style.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
