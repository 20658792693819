const routes = {
  MY_VEHICLES: {
    path: "/",
    name: "MyVehicles",
  },
  VEHICLE_DETAILS: {
    path: "/vehicle/:id",
    name: "VehicleDetails",
  },
  NEW_VEHICLE: {
    path: "/vehicle/",
    name: "NewVehicle",
  },
  PARKING_AGREEMENTS: {
    path: "/agreements/",
    name: "ParkingAgreements",
  },
  PARKING_AGREEMENT_DETAILS: {
    path: "/agreements/:id",
    name: "ParkingAgreementsDetails",
  },
};

export default routes;
