<template>
  <b-container>
    <b-row>
      <b-col>
        <h1 class="float-left">{{ $t("ParkingAgreementsListTitle") }}</h1>
      </b-col>
      <b-col>
        <router-link v-bind:to="{ name: 'MyVehicles' }">
          <b-button size="lg" class="float-right m-2 mr-5 action-btn center-it">
            <span>{{ $t("BackText") }}</span>
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <div class="page-separator"></div>

    <b-card>
      <b-card-text>
        <b-row>
          <b-col cols="12">
            <IconParking class="float-left mt-3 ml-4 mr-4" />
            <h3 class="mt-4">
              {{ $t("ParkingAgreementLicensePlate")
              }}<strong>{{ agreementNumber }}</strong>
            </h3>
          </b-col>
          <b-col class="ml-4">
            <b-badge
              v-if="agreement.type === 'ReservationParking'"
              variant="warning"
            >
              {{ $t("ResRequiredInfo") }}
            </b-badge>
          </b-col>
        </b-row>

        <b-row class="ml-0">
          <b-col>
            <p>{{ getValidDescription() }}</p>
            <p>{{ $t("ParkingAgreementDescriptionLine2") }}</p>
            <p>{{ $t("ParkingAgreementDescriptionLine3") }}</p>
          </b-col>
        </b-row>

        <b-row class="ml-0">
          <b-col>
            <div
              v-if="agreement.linkedLicensePlate"
              class="linked-licensePlate"
            >
              <b>
                {{ $t("LinkedLicensePlate") }}
                {{ agreement.linkedLicensePlate }}</b
              >
            </div>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <b-row class="my-5">
      <b-col>
        <h3>{{ $t("ParkingAgreementHistory") }}</h3>
        <hr />

        <ul>
          <li
            class="mb-3"
            v-for="(auditlog, index) in agreement.auditLog"
            v-bind:key="auditlog.timestamp"
          >
            <b-row>
              <b-col>
                <span
                  v-html="
                    $t('AgreementChangedVehicle', {
                      person: auditlog.personDisplayName,
                      newVehicle: auditlog.newLicensePlate,
                    })
                  "
                />

                <br />
                <small :id="'tooltipdate' + index">
                  {{ timeago(auditlog.timestamp) }}
                </small>
              </b-col>
            </b-row>
            <b-tooltip :target="'tooltipdate' + index">
              <h4>
                {{
                  new Date(auditlog.timestamp).toLocaleString(i18n.locale, {
                    dateStyle: "full",
                    timeStyle: "short",
                  })
                }}
              </h4>
            </b-tooltip>
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import IconParking from "@/components/IconParking";
import ParkingAgreement from "@/store/ParkingAgreement";
import i18n from "@/localization";

export default {
  name: "ParkingAgreementDetails",
  components: { IconParking },
  data() {
    return {
      agreement: ParkingAgreement.EmptyInstance(),
    };
  },
  computed: {
    agreementNumber() {
      if (this.$store.getters.selectedAgreement.title) {
        return ": " + this.$store.getters.selectedAgreement.title;
      } else {
        return "";
      }
    },
  },
  mounted() {
    if (this.$store.getters.selectedAgreement) {
      this.agreement = this.$store.getters.selectedAgreement;
    }
  },
  methods: {
    getValidDescription() {
      if (this.agreement.type === "ReservationParking") {
        if (this.agreement.code === "Sommeravtale/30") {
          return this.$i18n.t("ParkingAgreementDescriptionLine1Sommer");
        } else {
          return this.$i18n.t("ParkingAgreementDescriptionLine1Reservation");
        }
      } else if (this.agreement.type === "LongTermParking") {
        if (this.agreement.code === "Parkering") {
          return this.$i18n.t("ParkingAgreementDescriptionLine1Parkering");
        } else {
          return this.$i18n.t("ParkingAgreementDescriptionLineLongTerm");
        }
      }
    },
    i18n: i18n,
    timeago(dateString) {
      if (!dateString) {
        return null;
      }

      const date = new Date(dateString);

      const units = {
        year: 24 * 60 * 60 * 1000 * 365,
        month: (24 * 60 * 60 * 1000 * 365) / 12,
        day: 24 * 60 * 60 * 1000,
        hour: 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000,
      };

      const rtf = new Intl.RelativeTimeFormat(i18n.locale, { numeric: "auto" });

      const elapsed = date.getTime() - new Date().getTime();

      // "Math.abs" accounts for both "past" & "future" scenarios
      for (const u in units) {
        if (Math.abs(elapsed) > units[u] || u === "second") {
          return rtf.format(Math.round(elapsed / units[u]), u);
        }
      }

      return date.toLocaleString(); // 10. January 2017. at 10:20
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/style/style";
</style>
